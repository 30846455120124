@charset "utf-8";

@use '../../shared/scss/variable'  as *;
@use '../../shared/scss/mixin'  as *;
@use "sass:math";

//-------------------------------------------
// functions
//-------------------------------------------
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    $denominator: $number * 0 + 1;
    @return math.div($number, $denominator);
    // @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function w($_size, $_base-width: 1200px) {
  $size: $_size;
  @if ($_base-width != false) {
    $molecule: strip-unit($size) * 1vw;
    $denominator: strip-unit($_base-width) * 0.01;
    $size: math.div($molecule, $denominator);
  }
  @return $size;
}

@function s($_size, $_base-width: 1200px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: strip-unit($size) * 1px;
  }
  @return $size;
}

@function sw($_size, $_base-width: 375px) {
  $size: $_size;
  @if ($_base-width != false) {
    $molecule: strip-unit($size) * 1vw;
    $denominator: strip-unit($_base-width) * 0.01;
    $size: math.div($molecule, $denominator);
  }
  @return $size;
}

@function cl($_size, $_base-width: 1200px) {
  $size: $_size;
  $max: strip-unit($size) * 1px;
  $min: (strip-unit($size) * 0.75) * 1px;
  @if ($_base-width != false) {
    $molecule: strip-unit($size) * 1vw;
    $denominator: strip-unit($_base-width) * 0.01;
    $size: math.div($molecule, $denominator);
  }
  @return clamp($min, $size, $max);
}

//-------------------------------------------
// variable
//-------------------------------------------
$news-color-gray1: #f5f5f5;
$news-color-gray2: #e5e5e5;
$news-color-gray3: #eee;


//-------------------------------------------
// mixin
//-------------------------------------------
@mixin articleMargin1(){
  margin:w(60) 0;
    @include bpTablet{
      margin:sw(40) 0;
    }
    @include bpSP{
      margin:sw(60) 0;
    }
}

